import { stringify } from 'query-string';

import popupCenter from '~/utils/popupCenter';

export const shareBaseURL = 'https://www.finn.com';
export enum SharePlatform {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedIn',
}

const openShareWindow = (platform: SharePlatform) => {
  let url = '';
  switch (platform) {
    case SharePlatform.FACEBOOK: {
      url = `https://www.facebook.com/sharer/sharer.php?${stringify({
        u: `${shareBaseURL}${window.location.pathname}`,
      })}`;
      break;
    }
    case SharePlatform.TWITTER: {
      url = `https://twitter.com/share?${stringify({
        url: `${shareBaseURL}${window.location.pathname}`,
      })}`;
      break;
    }
    case SharePlatform.LINKEDIN: {
      url = `https://linkedin.com/shareArticle?${stringify({
        url: `${shareBaseURL}${window.location.pathname}`,
        mini: 'true',
      })}`;
      break;
    }
    default: {
      break;
    }
  }
  popupCenter({ url, title: 'Share', w: 720, h: 600 });
};

export default openShareWindow;
